<template>
  <div>
    <el-form ref="checkForm" :model="listQuery" label-width="140px">
      <el-row class="search">
        <el-col :span="18">
          <el-row>
            <el-col :span="6">
              <el-form-item label="报警来源">
                <el-select
                  placeholder="请选择报警来源"
                  v-model="listQuery.alertSource"
                >
                  <el-option label="一键报警" value="一键报警"></el-option>
                  <el-option label="场站火警" value="场站火警"></el-option>
                  <el-option label="全部" value></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="报警等级">
                <el-select
                  placeholder="请选择报警等级"
                  v-model="listQuery.alertLevel"
                >
                  <el-option
                    v-for="i in 10"
                    :key="i"
                    :label="`${i}级`"
                    :value="i"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="报警类型">
                <el-input
                  v-model="listQuery.alertCode"
                  placeholder="报警类型"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="所属机构">
                <selectOrg ref="selectOrg" @select="selectOrg"></selectOrg>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-col :span="6">
                <el-form-item label="关联组织部门">
                  <el-select
                    placeholder="关联组织部门"
                    v-model="listQuery.isRelatedOrgDept"
                    style="min-width:80px;"
                  >
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6">
          <el-row>
            <el-col :span="24">
              <el-form-item>
                <el-button type="primary" @click="search">查询</el-button>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="reset">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-button type="primary" @click="add">新增</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>

    <div class="jy_table">
      <ctable
        ref="ctable"
        :columns="columns"
        :data="tableList"
        :total="total"
        operateWidth="200"
        isGenerate
        @cellClick="cellClick"
        @update="getList"
      >
      </ctable>
    </div>

    <alarmGroup
      :isEdit="isEdit"
      :show="showAlert"
      :data="selectData"
      @close="closeDialog"
    ></alarmGroup>
  </div>
</template>

<script>
import alarmGroup from "./alarmGroup";
import selectOrg from "@/components/dialog/selectOrg";
export default {
  name: "index",
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      const page = this.$refs.ctable.getPager();
      const result = await this.$http({
        url: "/alert/type/query",
        data: {
            ...this.listQuery,
            ...page
        },
      });
      this.tableList = result.detail.list;
      this.total = result.detail.total;
      this.tableList.forEach((v) => {
        v.btnList = this.gernerateBtns(v);
      });
    },
    reset() {
      this.listQuery = {
        alertSource: "",
        alertLevel: "",
        alertCode: "",
        orgId: "",
        isRelatedOrgDept: "1",
      };
      this.$refs.selectOrg.reset();
    },
    search() {
      this.getList();
    },
    closeDialog() {
      this.showAlert = false;
      this.getList();
    },
    add() {
      this.isEdit = false;
      this.showAlert = true;
    },
    cellClick(index, btnIndex, item) {
      if (btnIndex === 1) {
        this.selectData = item;
        if (item.alertTypeUser && item.alertTypeUser.length) {
          this.selectData.orgDeptIds = item.alertTypeUser.map((v) => v.userId);
        }
        this.selectData.id = item.typeId;
        this.isEdit = true;
        this.showAlert = true;
      } else if (btnIndex === 2) {
        this.$confirm("是否删除该条报警分类信息？", "删除报警分类", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$http({
            url: "/alert/type/delete",
            data: {
              id: item.typeId,
            },
          }).then((res) => {
            this.$message.success({
              message: res.msg,
              duration: 3000,
            });
            this.getList();
          });
        });
      } else if (btnIndex === 3) {
        this.$confirm("是否禁用该条报警分类信息？", "禁用报警分类", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$http({
            url: "/alert/type/disable",
            data: {
              id: item.typeId,
              status: 0,
            },
          }).then((res) => {
            this.$message.success({
              message: res.msg,
              duration: 3000,
            });
            this.getList();
          });
        });
      } else if (btnIndex === 4) {
        this.$confirm("是否启用该条报警分类信息？", "启用报警分类", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$http({
            url: "/alert/type/disable",
            data: {
              id: item.typeId,
              status: 1,
            },
          }).then((res) => {
            this.$message.success({
              message: res.msg,
              duration: 3000,
            });
            this.getList();
          });
        });
      }
    },
    selectOrg(data) {
      this.listQuery.orgId = data.orgId;
    },
    gernerateBtns(row) {
      let btns = [
        {
          index: 1,
          title: "编辑",
          type: "text",
        },
        {
          index: 2,
          title: "删除",
          type: "text",
        },
      ];
      if (row.status === "1") {
        btns.push({
          title: "禁用",
          index: 3,
          type: "text",
        });
      } else {
        btns.push({
          title: "启用",
          index: 4,
          type: "text",
        });
      }
      return btns;
    },
  },
  data() {
    return {
      tableList: [],
      columns: [
        {
          prop: "alertCode",
          title: "报警类型",
          width: 150,
        },
        {
          prop: "alertLevel",
          title: "报警等级",
          width: 150,
        },
        {
          prop: "alertSource",
          title: "报警来源",
          width: 150,
        },
        {
          prop: "orgName",
          title: "所属机构",
          width: 150,
        },
        {
          prop: "isRelatedOrgDept",
          title: "是否关联组织部门",
          width: 250,
          formatter: (index, key, item) => {
            return item[key] == "1" ? "是" : "否";
          },
        },
        {
          prop: "status",
          title: "状态",
          width: 250,
          formatter: (index, key, item) => {
            return item[key] == "1" ? "启用" : "禁用";
          },
        },
        {
          prop: "createUserName",
          title: "创建用户",
          width: 200,
        },
        {
          prop: "createTime",
          title: "创建时间",
          width: 150,
        },
      ],
      listQuery: {
        alertSource: "",
        alertLevel: "",
        alertCode: "",
        orgId: "",
        isRelatedOrgDept: "1",
      },
      total: 0,

      showAlert: false,
      selectData: {},

      isEdit: false,
    };
  },
  components: {
    alarmGroup,
    selectOrg,
  },
};
</script>
