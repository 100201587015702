<template>
  <!-- div用于控制子组件的mounted -->
  <div v-if="dialogVisible">
    <el-dialog
      :title="isEdit?'编辑报警分类':'新增报警分类'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="70%"
      @close="handleClose"
    >
      <el-steps
        :active="active"
        finish-status="success"
        simple
        style="margin-top: 20px"
      >
        <el-step title="设置报警分类"></el-step>
        <el-step title="关联组织部门"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
      <div class="firstStep" v-show="active === 0">
        <el-form
          :model="form"
          :rules="rules"
          ref="first"
          label-position="right"
          label-width="150px"
          style="margin-top:10px;"
        >
          <el-form-item label="报警来源" prop="alertSource">
            <el-select v-model="form.alertSource" placeholder="请选择报警等级">
              <el-option label="一键报警" value="一键报警"></el-option>
              <el-option label="场站火警" value="场站火警"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="报警等级" prop="alertLevel">
            <el-select v-model="form.alertLevel" placeholder="请选择报警等级">
              <el-option
                v-for="i in 10"
                :key="i"
                :label="`${i}级`"
                :value="i"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="报警类型" prop="alertCode">
            <el-input
              v-model="form.alertCode"
              placeholder="请输入报警类型"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否关联组织部门" prop="isRelatedOrgDept">
            <el-select
              v-model="form.isRelatedOrgDept"
              placeholder="是否关联组织部门"
            >
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="数据所属单位" prop="orgId">
            <selectOrg
              :currentOrgName="form.orgName"
              @select="selectOrg"
            ></selectOrg>
          </el-form-item>
        </el-form>
      </div>
      <div class="twoStep" v-show="active === 1">
        <p>请配置该突发事件报警类型需响应的组织部门和人员</p>
        <transfer
          :defaultData="toPersons"
          ref="transfer"
          @selectPersons="getPersons"
        ></transfer>
      </div>
      <div class="operateBtn">
        <el-button @click="up" v-if="active!= 0">上一步</el-button>
        <el-button @click="next" v-if="active != 2">下一步</el-button>
        <el-button type="primary" v-if="active == 2" @click="finish"
          >完成</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import transfer from "@/components/transfer";
import selectOrg from "@/components/dialog/selectOrg";
export default {
  name: "alarmGroup",
  props: {
    show: Boolean,
    data: Object,
    isEdit: Boolean
  },
  data() {
    return {
      dialogVisible: this.show,

      active: 0,

      form: {
        alertSource: "",
        alertLevel: "",
        alertCode: "",
        isRelatedOrgDept: "1",
        orgDeptIds: [],
        orgId: "",
      },

      rules: {
        alertSource: [
          { required: true, message: "请选择报警来源", trigger: "change" },
        ],
        alertLevel: [
          { required: true, message: "请选择报警等级", trigger: "change" },
        ],
        alertCode: [
          { required: true, message: "请输入报警类型", trigger: "blur" },
          { pattern: /^[\u4e00-\u9fa5]+$/, message: "只能输入汉字" },
        ],
        orgId: [
          { required: true, message: "请选择所属单位", trigger: "change" },
        ],
      },

      toPersons: [],
    };
  },
  watch: {
    show() {
      this.dialogVisible = this.show;
    },
    data: {
      handler() {
        if (this.isEdit) {
          this.form = this.data;
        }
      },
    },
  },
  methods: {
    selectOrg(data) {
      this.$set(this.form, "orgId", data.orgId);
    },
    getPersons(userIds) {
      this.form.orgDeptIds = userIds;
    },
    handleClose() {
      this.reset();
      this.$emit("close");
    },
    next() {
      if (this.active === 0) {
        this.$refs.first.validate((valide) => {
          if (valide) {
            this.active++;
          } else {
            return false;
          }
        });
      } else if (this.active === 1) {
        if (this.form.orgDeptIds) {
          this.active++;
        } else {
          this.$message({
            message: "请选择处理人员",
            type: "warning",
            showClose: true,
          });
          return false;
        }
      } else {
        this.active++;
      }
      if (this.active > 2) {
        this.active = 0;
      }
    },
    up() {
      if (this.active-- < 1) {
        this.active = 0;
      }
    },
    reset() {
      this.active = 0;
      this.form = {
        alertSource: "",
        alertLevel: "",
        alertCode: "",
        isRelatedOrgDept: "1",
        orgDeptIds: [],
      };
    },
    async finish() {
      this.active++;
      let result;
      if (this.isEdit) {
        result = await this.$http({
          url: "/alert/type/save",
          data: this.form,
        });
      } else {
        result = await this.$http({
          url: "/alert/type/update",
          data: this.form,
        });
      }
      this.$message.success({
        message: result.msg,
        duration: 3000,
      });
      this.handleClose();
    },
  },
  components: {
    transfer,
    selectOrg,
  },
};
</script>

<style lang="scss" scoped>
.el-input {
  width: 200px;
}
.operateBtn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
</style>
